

































































































































































































































































































::v-deep .thead-text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
